import React, { useState } from "react";
import { Button } from "@zendeskgarden/react-buttons";
import { Row, Grid, Col } from "@zendeskgarden/react-grid";
import { MD } from "@zendeskgarden/react-typography";
import { Modal } from "@zendeskgarden/react-modals";
import {
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from "@zendeskgarden/react-modals";
import styled from "styled-components";
import { Spinner } from "@zendeskgarden/react-loaders";
import { zendeskEfileApi } from "../../utils/axiosInstance";
import { useAlertsDispatch, alertsActions } from "../../context/AlertsContext";

const StyledRow = styled(Row)`
  margin-top: 15px;
`;

const StyledModal = styled(Modal)`
  width: 95%;
`;

const StyledSpinner = styled(Spinner)`
  margin-right: 10px;
`;

const CancelConfirmationModal = ({
  isModalVisible,
  setIsModalVisible,
  transmission,
  taxYear,
  setCancellationSuccessful,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    application,
    source,
    returnType,
    confirmationNumber,
    credentialsType,
    email,
    returnId,
  } = transmission;

  const dispatch = useAlertsDispatch();

  const cancelHandler = async () => {
    try {
      setIsLoading(true);

      const response = await zendeskEfileApi.delete(
        "/transmission/deletereturn",
        {
          params: {
            application,
            source,
            returnType,
            taxYear,
            confirmationNumber,
            credentialsType,
            email,
            returnId,
          },
        }
      );

      if (response.status !== 200) {
        dispatch({
          type: alertsActions.addErrorAlert,
          payload: {
            title: `Status Code: ${response.status}`,
            message: response.statusText,
          },
        });

        return;
      }

      if (!response.data.successful) {
        dispatch({
          type: alertsActions.addErrorAlert,
          payload: {
            title: "Unsuccessful",
            message: response.data.errorMessage || response.data.message,
          },
        });

        return;
      }

      dispatch({
        type: alertsActions.addSuccessAlert,
        payload: {
          title: "Success: Return Cancelled",
          message: "Return has been cancelled.",
        },
      });

      setCancellationSuccessful(true);
    } catch (error) {
      console.error(error.message);
      dispatch({
        type: alertsActions.addErrorAlert,
        payload: {
          title: "Fetch Error",
          message: "Error requesting cancellation of return.",
        },
      });
    } finally {
      setIsLoading(false);
      setIsModalVisible(false);
    }
  };

  return (
    <Row>
      <Col textAlign="center">
        {isModalVisible && (
          <StyledModal onClose={() => setIsModalVisible(false)}>
            <Header isDanger>
              Are you sure you want to cancel this return?
            </Header>
            <Body>
              This action will try to delete a return for tax payer{" "}
              <MD isBold>{transmission.taxpayerName}</MD>
              <br />
              Return Id: <MD isBold>{transmission.returnId}</MD>
            </Body>
            <Footer>
              <Grid>
                <Row>
                  <Col>
                    <FooterItem>
                      <Button
                        onClick={() => setIsModalVisible(false)}
                        isBasic
                        isStretched
                      >
                        Go Back
                      </Button>
                    </FooterItem>
                  </Col>
                </Row>
                <StyledRow>
                  <Col>
                    <FooterItem>
                      <Button
                        isPrimary
                        isDanger
                        isStretched
                        onClick={cancelHandler}
                      >
                        {isLoading ? (
                          <>
                            <StyledSpinner size="24" delayMS={0} /> Trying to
                            cancel...
                          </>
                        ) : (
                          "Confirm Cancellation"
                        )}
                      </Button>
                    </FooterItem>
                  </Col>
                </StyledRow>
              </Grid>
            </Footer>
            <Close aria-label="Close modal" />
          </StyledModal>
        )}
      </Col>
    </Row>
  );
};

export default CancelConfirmationModal;

 import React, { useState } from 'react';
 import { Dropdown, Field, Menu, Item, Select, Label } from '@zendeskgarden/react-dropdowns';
 import { Row, Col } from '@zendeskgarden/react-grid';
 import { useTransmissions, useTransmissionsDispatch, transmissionActions } from '../../context/TransmissionsContext';
 
 const StatusDropDown = () => {
    const { transmissionStatuses, selectedTransmissionStatus } = useTransmissions();
    const dispatch = useTransmissionsDispatch();
 
    const onTransmissionStatusSelect = (transmissionStatus) => {
        dispatch({
            type: transmissionActions.setTransmissionsStatus,
            payload: transmissionStatus
        })
    }

   return (
     <Row justifyContent="center">
       <Col sm={5}>
         <Dropdown
           selectedItem={selectedTransmissionStatus}
           onSelect={onTransmissionStatusSelect}
           downshiftProps={{ itemToString: item => item.label }}
         >
           <Field>
             <Label>Status</Label>
             <Select>{selectedTransmissionStatus.label}</Select>
           </Field>
           <Menu>
             {transmissionStatuses.map(status => (
               <Item key={status.value} value={status}>
                 {status.label}
               </Item>
             ))}
           </Menu>
         </Dropdown>
       </Col>
     </Row>
   );
 };
 
 export default StatusDropDown;
 
import React from "react"
import { Dots } from '@zendeskgarden/react-loaders';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Row, Col } from '@zendeskgarden/react-grid';

const Loading = () => {
    return (
        <Row>
            <Col textAlign="center">
                <Dots size={32} color={PALETTE.blue[600]} />
            </Col>
        </Row>
    )
}

export default Loading;
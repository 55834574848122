const setInputError = (
        isValueValid,
        setValidationType,
        setValidationMessage,
        validationType = 'error',
        validationMessage = 'Required'
    ) => {
    if (!isValueValid) {
        setValidationType(validationType);
        setValidationMessage(validationMessage);
    } else {
        setValidationType(null);
        setValidationMessage('');
    }
}

export default setInputError;
import { createContext, useReducer, useContext } from "react";

const initialState = {
    isLoading: false,
    queryType: 'confirmationNumber',
    transmissions: [],
    transmissionDetail: '',
    selectedTransmission: null,
    transmissionLogs: [],
    transmissionStatuses: [
        { label: "All",       value: null },
        { label: "Success",   value: 0 },
        { label: "Exception", value: 1 },
    ],
    selectedTransmissionStatus: { label: "All", value: null },
    taxYears: [],
    selectedTaxYear: { year: 'Select a tax year' },
    taxYearReturns: [],
    selectedTaxYearReturn: { returnTypeText: 'Select a return type' },
    agencies: [
        { label: 'Hacienda', value: 'hac' },
        { label: 'IRS', value: 'irs' }
    ],
    selectedAgency: { label: 'Hacienda', value: 'hac' },
    confirmationNumber: '',
    taxPayerName: '',
    last4SSN: '',
    email: ''
}

const TransmissionsContext = createContext(initialState);
const TransmissionsDispatchContext = createContext(null);

export const TransmissionsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(transmissionsReducer, initialState);

    return (
        <TransmissionsContext.Provider value={state}>
            <TransmissionsDispatchContext.Provider value={dispatch}>
                {children}
            </TransmissionsDispatchContext.Provider>
        </TransmissionsContext.Provider>
    )
}

export const useTransmissions = () => {
    return useContext(TransmissionsContext);
}

export const useTransmissionsDispatch = () => {
    return useContext(TransmissionsDispatchContext);
}

export const transmissionActions = {
    setAgencyType: "SET_AGENCY_TYPE",
    setTaxYears: "SET_TAX_YEARS",
    setTaxYear: "SET_TAX_YEAR",
    resetSelectedTaxYear: "RESET_SELECTED_TAX_YEAR",
    setTaxYearReturns: "SET_TAX_YEAR_RETURNS",
    setTaxYearReturn: "SET_TAX_YEAR_RETURN",
    resetSelectedTaxYearReturn: "RESET_SELECTED_TAX_YEAR_RETURN",
    setConfirmationNumber: "SET_CONFIRMATION_NUMBER",
    setTaxPayerName: "SET_TAX_PAYER_NAME",
    setLast4SSN: "SET_LAST_4_SSN",
    setEmail: "SET_EMAIL",
    resetConfirmationNumber: "RESET_CONFIRMATION_NUMBER",
    resetTaxPayerName: "RESET_TAX_PAYER_NAME",
    resetLast4SSN: "RESET_LAST_4_SSN",
    resetEmail: "RESET_EMAIL",
    setTransmissions: "SET_TRANSMISSIONS",
    setTransmissionDetail: "SET_TRANSMISSION_DETAIL",
    resetTransmissionDetail: "RESET_TRANSMISSION_DETAIL",
    setTransmissionsStatus: "SET_TRANSMISSION_STATUS",
    resetTransmissionsStatus: "RESET_TRANSMISSION_STATUS",
    setTransmissionLogs: "SET_TRANSMISSION_LOGS",
    resetTransmissionLogs: "RESET_TRANSMISSION_LOGS",
    setQueryType: "SET_QUERY_TYPE",
    isLoading: "IS_LOADING",
    finishedLoading: "FINISHED_LOADING"
}

const transmissionsReducer = (state, action) => {
    const { 
        setAgencyType,
        setTaxYear,
        setTaxYears,
        resetSelectedTaxYear,
        setTaxYearReturns,
        setTaxYearReturn,
        resetSelectedTaxYearReturn,
        setConfirmationNumber,
        setTaxPayerName,
        setLast4SSN,
        setEmail,
        resetConfirmationNumber,
        resetTaxPayerName,
        resetLast4SSN,
        resetEmail,
        setTransmissions,
        setTransmissionDetail,
        resetTransmissionDetail,
        setTransmissionsStatus,
        resetTransmissionsStatus,
        setTransmissionLogs,
        resetTransmissionLogs,
        setQueryType,
        isLoading,
        finishedLoading
    } = transmissionActions;

    switch(action.type) {
        case setAgencyType: {
            return {
                ...state,
                selectedAgency: action.payload
            }
        }

        case setTaxYear: {
            return {
                ...state,
                selectedTaxYear: action.payload
            }
        }

        case resetSelectedTaxYear: {
            return {
                ...state,
                selectedTaxYear: { year: 'Select a tax year' }
            }
        }

        case setTaxYears: {
            return {
                ...state,
                taxYears: action.payload
            }
        }

        case setTaxYearReturns: {
            return {
                ...state,
                taxYearReturns: action.payload
            }
        } 

        case setTaxYearReturn: {
            return {
                ...state,
                selectedTaxYearReturn: action.payload
            }
        }

        case resetSelectedTaxYearReturn: {
            return {
                ...state,
                selectedTaxYearReturn: { returnTypeText: 'Select a return type' }
            }
        }

        case setConfirmationNumber: {
            return {
                ...state,
                confirmationNumber: action.payload
            }
        }

        case setTaxPayerName: {
            return {
                ...state,
                taxPayerName: action.payload
            }
        }

        case setLast4SSN: {
            return {
                ...state,
                last4SSN: action.payload
            }
        }
            
        case setEmail: {
            return {
                ...state,
                email: action.payload
            }
        }

        case resetConfirmationNumber: {
            return {
                ...state,
                confirmationNumber: ''
            }
        }

        case resetTaxPayerName: {
            return {
                ...state,
                taxPayerName: ''
            }
        }

        case resetLast4SSN: {
            return {
                ...state,
                last4SSN: ''
            }
        }
            
        case resetEmail: {
            return {
                ...state,
                email: ''
            }
        }

        case setTransmissions: {
            return {
                ...state,
                transmissions: action.payload
            }
        }

        case setTransmissionDetail: {
            return {
                ...state,
                transmissionDetail: action.payload
            }
        }

        case resetTransmissionDetail: {
            return {
                ...state,
                transmissionDetail: ''
            }
        }

        case setTransmissionsStatus: {
            return {
                ...state,
                selectedTransmissionStatus: action.payload
            }
        }

        case resetTransmissionsStatus: {
            return {
                ...state,
                selectedTransmissionStatus: { label: "All", value: null }
            }
        }

        case setTransmissionLogs: {
            return {
                ...state,
                transmissionLogs: action.payload
            }
        }

        case resetTransmissionLogs: {
            return {
                ...state,
                transmissionLogs: []
            }
        }

        case setQueryType: {
            return {
                ...state,
                queryType: action.payload
            }
        }

        case isLoading: {
            return {
                ...state,
                isLoading: true
            }
        }

        case finishedLoading: {
            return {
                ...state,
                isLoading: false
            }
        }

        default: {
            return state;
        }
    }
}
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Timeline } from "@zendeskgarden/react-accordions";
import { Row, Col } from "@zendeskgarden/react-grid";
import { Span } from "@zendeskgarden/react-typography";
import TransmissionLog from "./TransmissionLog";
import { useLocation } from "react-router-dom";
import {
  useTransmissions,
  useTransmissionsDispatch,
  transmissionActions,
} from "../../context/TransmissionsContext";
import { zendeskEfileApi } from "../../utils/axiosInstance";
import ErrorPage from "../Utilities/ErrorPage";
import Loading from "../Utilities/Loading";

export const StyledSpan = styled(Span).attrs({ isBold: true })`
  display: block;
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

const TransmissionLogsList = ({
  returnId,
  taxYear,
  agency,
  returnType,
  wasCancellationSuccessful,
  isRefreshingStatus,
  transmission,
}) => {
  const { transmissionLogs } = useTransmissions();
  const dispatch = useTransmissionsDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getLogs = async () => {
    try {
      const response = await zendeskEfileApi.get("/logs/gettransmissionlogs", {
        params: {
          agency,
          taxYear,
          returnType,
          returnId,
        },
      });

      const logs = response.data.logs.sort(
        (a, b) => new Date(b.logDate) - new Date(a.logDate)
      );

      dispatch({
        type: transmissionActions.setTransmissionLogs,
        payload: logs,
      });
    } catch (error) {
      setError("Error fetching transmission logs.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLogs();
  }, [wasCancellationSuccessful, isRefreshingStatus, transmission.statusText]);

  if (isLoading) {
    return <Loading />;
  }

  if (error.length > 0) {
    return <ErrorPage message={error} />;
  }

  if (transmissionLogs.length === 0) {
    return <ErrorPage message="No transmission logs found." />;
  }

  return (
    <StyledRow justifyContent="center">
      <Col sm="auto">
        <Timeline>
          {transmissionLogs.map((transmissionLog) => (
            <TransmissionLog
              key={transmissionLog.id}
              transmissionLog={transmissionLog}
              returnId={returnId}
              taxYear={taxYear}
              agency={agency}
              returnType={returnType}
            />
          ))}
        </Timeline>
      </Col>
    </StyledRow>
  );
};

export default TransmissionLogsList;

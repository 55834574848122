import React from 'react';
import { Dropdown, Field, Menu, Item, Select, Label, Message } from '@zendeskgarden/react-dropdowns';
import { Row, Col } from '@zendeskgarden/react-grid';
import { transmissionActions, useTransmissions, useTransmissionsDispatch,  } from '../../context/TransmissionsContext';

const AgencyDropdown = ({ validationType, validationMessage }) => {
  const { agencies, selectedAgency } = useTransmissions();
  const dispatch = useTransmissionsDispatch();

  const onAgencySelect = (agency) => {
    dispatch({ 
      type: transmissionActions.setAgencyType, 
      payload: agency
    });

    dispatch({ type: transmissionActions.resetSelectedTaxYear });
    dispatch({ type: transmissionActions.resetSelectedTaxYearReturn });
  }

  return (
    <Row justifyContent="center">
      <Col sm={5}>
        <Dropdown
          selectedItem={selectedAgency}
          onSelect={onAgencySelect}
          required
          downshiftProps={{ itemToString: (item) => item && item.label }}
        >
          <Field>
            <Label>Agency</Label>
            <Select validation={validationType}>{selectedAgency.label}</Select>
            <Message validation={validationType}>{validationMessage}</Message>
          </Field>
          <Menu>
            {agencies.map(agency => (
              <Item key={agency.value} value={agency}>
                {agency.label}
              </Item>
            ))}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default AgencyDropdown;

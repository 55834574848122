import React, { useState } from 'react';
import { Body, Caption, Cell, Head, HeaderCell, HeaderRow, Row, Table } from '@zendeskgarden/react-tables'
import { LG } from '@zendeskgarden/react-typography';
import { ReactComponent as ListIcon } from '@zendeskgarden/svg-icons/src/16/list-number-fill.svg';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import ErrorPage from '../../components/Utilities/ErrorPage';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { useTransmissions } from '../../context/TransmissionsContext';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loading from '../../components/Utilities/Loading';

const TransmissionsList = ({ rowData, isLoading, error }) => {
    const { selectedTaxYearReturn, selectedTaxYear } = useTransmissions();

    const history = useHistory();
    const location = useLocation();
    const params = location.state;

    const {
        confirmationNumber,
        returnType,
        taxYear,
        taxPayerName,
        last4SSN,
        agency
    } = params;

    if (isLoading) {
        return <Loading />
    }

    if (error.length > 0) {
        return <ErrorPage message={error} />
    }

    if (rowData.length === 0) {
        return <ErrorPage message='No transmission found.' />
    }

    return (
        <Table size="small" style={{ overflowY: 'auto' }}>
            <Caption>
                <LG>Search Results</LG>
            </Caption>
            <Head>
                <HeaderRow>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Confirmation Number</HeaderCell>
                    <HeaderCell></HeaderCell>
                </HeaderRow>
            </Head>
            <Body>
                {rowData.map(data => (
                    <Row key={data.id}>
                        <Cell isTruncated onClick={() => copyToClipboard(data.taxpayerName)}>
                            <Tooltip content="Click to copy">
                                <span>{data.taxpayerName}</span>
                            </Tooltip> 
                        </Cell>
                        <Cell isTruncated onClick={() => copyToClipboard(data.confirmationNumber)}>
                            <Tooltip content="Click to copy">
                                <span>{data.confirmationNumber}</span>
                            </Tooltip> 
                        </Cell>
                        <Cell>
                            <Link to={{
                                pathname: '/transmissions/:id',
                                state: {
                                    confirmationNumber,
                                    transmissionId: data.id,
                                    returnType,
                                    taxYear,
                                    taxPayerName,
                                    last4SSN,
                                    agency
                                }
                            }}>
                                View
                            </Link>
                        </Cell>
                    </Row>
                ))}
            </Body>
        </Table>
    );
};

export default TransmissionsList;
 import React, { useState } from 'react';
 import { Fieldset, Field, Label, Radio, Message } from '@zendeskgarden/react-forms';
 import { Row, Col } from '@zendeskgarden/react-grid';
 import { useTransmissions, useTransmissionsDispatch, transmissionActions } from '../../context/TransmissionsContext';
 
 const QueryType = () => {
   const { queryType } = useTransmissions();
   const dispatch = useTransmissionsDispatch();

   const onQueryTypeSelect = (event) => {
       dispatch({
           type: transmissionActions.setQueryType,
           payload: event.target.value
       })

       dispatch({ type: transmissionActions.resetTaxPayerName })
       dispatch({ type: transmissionActions.resetConfirmationNumber })
       dispatch({ type: transmissionActions.resetLast4SSN })
       dispatch({ type: transmissionActions.resetEmail })
   }
 
   return (
     <Row>
       <Col size="auto">
         <Fieldset>
           <Fieldset.Legend>Search By</Fieldset.Legend>
           <Field>
             <Radio
               value="confirmationNumber"
               checked={queryType === 'confirmationNumber'}
               onChange={onQueryTypeSelect}
             >
               <Label isRegular>Confirmation Number</Label>
             </Radio>
           </Field>
           <Field>
             <Radio
               value="nameAndSSN"
               checked={queryType === 'nameAndSSN'}
               onChange={onQueryTypeSelect}
             >
               <Label isRegular>Taxpayer name and SSN</Label>
             </Radio>
           </Field>
           <Field>
             <Radio
               value="email"
               checked={queryType === 'email'}
               onChange={onQueryTypeSelect}
             >
               <Label isRegular>Email</Label>
             </Radio>
           </Field>
         </Fieldset>
       </Col>
     </Row>
   );
 };
 
 export default QueryType;
 

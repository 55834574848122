import { XL, LG } from "@zendeskgarden/react-typography";
import { useMsal } from "@azure/msal-react";
import styled from "styled-components";
import { Button } from "@zendeskgarden/react-buttons";
import { loginRequest } from "../../msalConfig";

const Container = styled.div`
  & > * + * {
    margin-top: 1rem;
  }
`;

export const SignIn = (isZendesk) => {
  const { instance } = useMsal();

  return (
    <Container>
      <XL>Welcome to the Zendesk Efile Tool</XL>
      <LG>Please sign in to continue</LG>
      <Button
        isPrimary
        isStretched
        onClick={() => {
          if (isZendesk) {
            instance.loginPopup(loginRequest).then((res) => {
              instance.setActiveAccount(res.account);
            });
          }
        }}
      >
        Sign in
      </Button>
    </Container>
  );
};

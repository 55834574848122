import React from 'react';
import Alert from './Alert';
import { useAlerts } from '../../context/AlertsContext';

const AlertList = () => {
  const { alerts } = useAlerts();

  return (
    <>
      {alerts.length > 0 ? (
        <ul>
          {alerts.map(alert => (
            <li key={alert.id}>
              <Alert alert={alert} />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  )
}

export default AlertList;
import { useMsal } from "@azure/msal-react";
import msalInstance from "@azure/msal-browser";
import { zendeskEfileApi } from "../../utils/axiosInstance";

export const AxiosInterceptors = ({ children }) => {
  const { instance, accounts } = useMsal();
  // const account = accounts[0] || {};
  const account = instance.getActiveAccount();

  const authRequest = {
    scopes: [process.env.REACT_APP_AAD_SCOPE],
    account,
  };

  zendeskEfileApi.interceptors.request.use(
    async (request) => {
      const res = await instance.acquireTokenSilent(authRequest);

      request.headers.Authorization = `Bearer ${res.accessToken}`;

      return request;
    },
    (error) => {
      console.error(error);

      return error;
    }
  );

  return children;
};

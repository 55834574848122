import React, { useEffect } from "react";
import {
  Dropdown,
  Menu,
  Item,
  Select,
  Field,
  Label,
  Message,
} from "@zendeskgarden/react-dropdowns";
import {
  useTransmissions,
  useTransmissionsDispatch,
  transmissionActions,
} from "../../context/TransmissionsContext";
import { zendeskEfileApi } from "../../utils/axiosInstance";

const ReturnTypeField = ({ validationType, validationMessage }) => {
  const {
    taxYearReturns,
    selectedTaxYearReturn,
    selectedAgency,
    selectedTaxYear,
  } = useTransmissions();
  const dispatch = useTransmissionsDispatch();

  const onFormTypeSelect = (taxYearReturn) => {
    dispatch({
      type: transmissionActions.setTaxYearReturn,
      payload: taxYearReturn,
    });
  };

  const getTaxYearReturns = async () => {
    const isAgencyIRS = selectedAgency.value === "irs";
    const irsEndpoint = "/taxyearreturns/getirsavailablereturnsfortaxyear";
    const hacEndpoint = "/taxyearreturns/gethacavailablereturnsfortaxyear";

    const response = await zendeskEfileApi.get(
      isAgencyIRS ? irsEndpoint : hacEndpoint,
      {
        params: {
          taxYear: selectedTaxYear.year,
        },
      }
    );
    const taxYearReturns = response.data;

    const formattedTaxYearReturns = taxYearReturns.map((taxYearReturn) => {
      if (taxYearReturn.returnTypeText === "1040-PR") {
        taxYearReturn.returnTypeText = "1040-PR/SS";
      }

      return taxYearReturn;
    });

    dispatch({
      type: transmissionActions.setTaxYearReturns,
      payload: formattedTaxYearReturns,
    });
  };

  useEffect(() => {
    getTaxYearReturns();
  }, [selectedAgency, selectedTaxYear]);

  return (
    <Dropdown
      selectedItem={selectedTaxYearReturn}
      onSelect={onFormTypeSelect}
      required
      downshiftProps={{ itemToString: (item) => item && item.returnTypeText }}
    >
      <Field>
        <Label>Return Type</Label>
        <Select validation={validationType}>
          {selectedTaxYearReturn.returnTypeText}
        </Select>
        <Message validation={validationType}>{validationMessage}</Message>
      </Field>
      <Menu>
        {taxYearReturns.map((taxYearReturn) => (
          <Item key={taxYearReturn.id} value={taxYearReturn}>
            {taxYearReturn.returnTypeText}
          </Item>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default ReturnTypeField;

import React, { Suspense, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Grid, Col } from "@zendeskgarden/react-grid";
import AgencyDropDown from "./AgencyDropdown";
import TaxYearField from "./TaxYearField";
import ReturnTypeField from "./ReturnTypeField";
import TextField from "./TextField";
import Loading from "../../components/Utilities/Loading";
import { Button } from "@zendeskgarden/react-buttons";
import { ReactComponent as SearchStroke } from "@zendeskgarden/svg-icons/src/16/search-stroke.svg";
import {
  useTransmissions,
  useTransmissionsDispatch,
  transmissionActions,
} from "../../context/TransmissionsContext";
import { useHistory } from "react-router-dom";
import QueryType from "./QueryType";
import setInputError from "../../utils/setInputError";

const StyledRow = styled(Row)`
  margin-top: 15px;
`;

const SearchPage = () => {
  const history = useHistory();

  const isInitialRender = useRef(true);

  const [canSubmit, setCanSubmit] = useState(false);

  const [
    confirmationNumberValidationType,
    setConfirmationNumberValidationType,
  ] = useState(null);
  const [taxPayerNameValidationType, setTaxPayerNameValidationType] =
    useState(null);
  const [last4SSNValidationType, setLast4SSNValidationType] = useState(null);
  const [agencyValidationType, setAgencyValidationType] = useState(null);
  const [taxYearValidationType, setTaxYearValidationType] = useState(null);
  const [taxYearReturnValidationType, setTaxYearReturnValidationType] =
    useState(null);
  const [emailValidationType, setEmailValidationType] = useState(null);

  const [
    confirmationNumberValidationMessage,
    setConfirmationNumberValidationMessage,
  ] = useState("");
  const [taxPayerNameValidationMessage, setTaxPayerNameValidationMessage] =
    useState("");
  const [last4SSNValidationMessage, setLast4SSNValidationMessage] =
    useState("");
  const [agencyValidationMessage, setAgencyValidationMessage] = useState("");
  const [taxYearValidationMessage, setTaxYearValidationMessage] = useState("");
  const [taxYearReturnValidationMessage, setTaxYearReturnValidationMessage] =
    useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState("");

  const {
    confirmationNumber,
    taxPayerName,
    last4SSN,
    email,
    selectedTaxYearReturn,
    selectedTaxYear,
    selectedAgency,
    queryType,
  } = useTransmissions();

  const dispatch = useTransmissionsDispatch();

  const onConfirmationNumberChange = (event) => {
    dispatch({
      type: transmissionActions.setConfirmationNumber,
      payload: event.target.value,
    });
  };

  const onTaxPayerNameChange = (event) => {
    dispatch({
      type: transmissionActions.setTaxPayerName,
      payload: event.target.value,
    });
  };

  const onLast4SSNChange = (event) => {
    dispatch({
      type: transmissionActions.setLast4SSN,
      payload: event.target.value,
    });
  };

  const onEmailChange = (event) => {
    dispatch({
      type: transmissionActions.setEmail,
      payload: event.target.value,
    });
  };

  const clearTextInputs = (event) => {
    event.preventDefault();

    dispatch({ type: transmissionActions.resetTaxPayerName });

    dispatch({ type: transmissionActions.resetConfirmationNumber });

    dispatch({ type: transmissionActions.resetLast4SSN });

    dispatch({ type: transmissionActions.resetEmail });

    setCanSubmit(false);
  };

  const isConfirmationNumberValid = confirmationNumber.trim().length > 0;
  const isTaxPayerNameValid = taxPayerName.trim().length > 0;
  const isLast4SSNValid = last4SSN.trim().length === 4;
  const isAgencyValid =
    selectedAgency.value === "irs" || selectedAgency.value === "hac";
  const isTaxYearValid = selectedTaxYear.year !== "Select a tax year";
  const isTaxYearReturnValid =
    selectedTaxYearReturn.returnTypeText !== "Select a return type";
  const isEmailValid = email.trim().length > 0;

  const showInputErrors = () => {
    setInputError(
      isAgencyValid,
      setAgencyValidationType,
      setAgencyValidationMessage
    );
    setInputError(
      isTaxYearValid,
      setTaxYearValidationType,
      setTaxYearValidationMessage
    );
    setInputError(
      isTaxYearReturnValid,
      setTaxYearReturnValidationType,
      setTaxYearReturnValidationMessage
    );
    setInputError(
      isConfirmationNumberValid,
      setConfirmationNumberValidationType,
      setConfirmationNumberValidationMessage
    );
    setInputError(
      isTaxPayerNameValid,
      setTaxPayerNameValidationType,
      setTaxPayerNameValidationMessage
    );
    setInputError(
      isLast4SSNValid,
      setLast4SSNValidationType,
      setLast4SSNValidationMessage,
      "error",
      "Required. Needs to contain four characters."
    );
    setInputError(
      isEmailValid,
      setEmailValidationType,
      setEmailValidationMessage
    );
  };

  const validate = () => {
    if (isAgencyValid && isTaxYearValid && isTaxYearReturnValid) {
      if (queryType === "confirmationNumber") {
        if (isConfirmationNumberValid) {
          return true;
        }
      }

      if (queryType === "nameAndSSN") {
        if (isTaxPayerNameValid && isLast4SSNValid) {
          return true;
        }
      }

      if (queryType === "email") {
        if (isEmailValid) {
          return true;
        }
      }
    }

    return false;
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (canSubmit) {
      history.push({
        pathname: "/transmissions",
        state: {
          confirmationNumber,
          returnType: selectedTaxYearReturn.returnType,
          taxYear: selectedTaxYear.year,
          taxPayerName,
          last4SSN,
          email,
          agency: selectedAgency.value,
        },
      });
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;

      const isValid = validate();
      setCanSubmit(isValid);

      return;
    }

    showInputErrors();

    const isValid = validate();
    setCanSubmit(isValid);
  }, [
    confirmationNumber,
    taxPayerName,
    last4SSN,
    email,
    selectedAgency.value,
    selectedTaxYear.year,
    selectedTaxYearReturn.returnTypeText,
  ]);

  return (
    <Suspense fallback={<Loading />}>
      <form onSubmit={onSubmitHandler}>
        <Grid style={{ overflowY: "auto" }}>
          <StyledRow>
            <Col>
              <AgencyDropDown
                validationType={agencyValidationType}
                validationMessage={agencyValidationMessage}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <TaxYearField
                validationType={taxYearValidationType}
                validationMessage={taxYearValidationMessage}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <ReturnTypeField
                validationType={taxYearReturnValidationType}
                validationMessage={taxYearReturnValidationMessage}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <QueryType />
            </Col>
          </StyledRow>
          {queryType === "confirmationNumber" && (
            <StyledRow>
              <Col>
                <TextField
                  label="Confirmation Number"
                  value={confirmationNumber}
                  onChange={onConfirmationNumberChange}
                  isRequired={queryType === "confirmationNumber"}
                  validationType={confirmationNumberValidationType}
                  validationMessage={confirmationNumberValidationMessage}
                />
              </Col>
            </StyledRow>
          )}
          {queryType === "nameAndSSN" && (
            <>
              <StyledRow>
                <Col>
                  <TextField
                    label="Taxpayer Name"
                    value={taxPayerName}
                    onChange={onTaxPayerNameChange}
                    isRequired={queryType === "nameAndSSN"}
                    validationType={taxPayerNameValidationType}
                    validationMessage={taxPayerNameValidationMessage}
                  />
                </Col>
              </StyledRow>
              <StyledRow>
                <Col>
                  <TextField
                    label="Last 4 SSN"
                    value={last4SSN}
                    onChange={onLast4SSNChange}
                    isRequired={queryType === "nameAndSSN"}
                    validationType={last4SSNValidationType}
                    validationMessage={last4SSNValidationMessage}
                  />
                </Col>
              </StyledRow>
            </>
          )}
          {queryType === "email" && (
            <>
              <StyledRow>
                <Col>
                  <TextField
                    label="Email"
                    value={email}
                    onChange={onEmailChange}
                    isRequired={queryType === "email"}
                    validationType={emailValidationType}
                    validationMessage={emailValidationMessage}
                  />
                </Col>
              </StyledRow>
            </>
          )}
          <StyledRow>
            <Col>
              <Button isStretched isPrimary disabled={!canSubmit} type="submit">
                <Button.StartIcon>
                  <SearchStroke />
                </Button.StartIcon>
                Search Transmissions
              </Button>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <Button isStretched onClick={clearTextInputs}>
                Clear Text Inputs
              </Button>
            </Col>
          </StyledRow>
        </Grid>
      </form>
    </Suspense>
  );
};

export default SearchPage;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { zendeskEfileApi } from "../../utils/axiosInstance";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Button } from "@zendeskgarden/react-buttons";
import ResultsList from "./TransmissionList";
import StatusDropDown from "./StatusDropDown";
import { Row, Grid, Col } from "@zendeskgarden/react-grid";
import { ReactComponent as LeftArrowUpStroke } from "@zendeskgarden/svg-icons/src/16/arrow-left-up-stroke.svg";
import {
  useTransmissions,
  useTransmissionsDispatch,
  transmissionActions,
} from "../../context/TransmissionsContext";

const StyledRow = styled(Row)`
  margin-top: 20px;
`;

const TransmissionsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const params = location.state;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const {
    confirmationNumber,
    returnType,
    taxYear,
    taxPayerName,
    last4SSN,
    email,
    agency,
  } = params;

  const { transmissions, selectedTransmissionStatus, queryType } =
    useTransmissions();

  const [filteredTransmissionsByStatus, setFilteredTransmissionsByStatus] =
    useState(transmissions);

  const dispatch = useTransmissionsDispatch();

  const getTransmissionsByConfirmationNumber = async () => {
    try {
      const isAgencyIRS = agency === "irs";

      const irsEndpoint =
        "/transmission/getirstransmissionsbyconfirmationnumber";
      const hacEndpoint =
        "/transmission/gethactransmissionsbyconfirmationnumber";

      const response = await zendeskEfileApi.get(
        isAgencyIRS ? irsEndpoint : hacEndpoint,
        {
          params: {
            taxYear,
            returnType,
            confirmationNumber,
          },
        }
      );
      const transmissions = response.data;

      dispatch({
        type: transmissionActions.setTransmissions,
        payload: transmissions,
      });
    } catch (error) {
      setError("Error fetching transmissions.");
    } finally {
      setIsLoading(false);
    }
  };

  const getTransmissionsByTaxPayerNameAndSSN = async () => {
    try {
      const isAgencyIRS = agency === "irs";

      const irsEndpoint = "/transmission/getirstransmissionsbynameandssn";
      const hacEndpoint = "/transmission/gethactransmissionsbynameandssn";

      const response = await zendeskEfileApi.get(
        isAgencyIRS ? irsEndpoint : hacEndpoint,
        {
          params: {
            taxYear,
            returnType,
            taxPayerName,
            last4SSN,
          },
        }
      );
      const transmissions = response.data;

      dispatch({
        type: transmissionActions.setTransmissions,
        payload: transmissions,
      });
    } catch (error) {
      setError("Error fetching transmissions.");
    } finally {
      setIsLoading(false);
    }
  };

  const getTransmissionsByEmail = async () => {
    try {
      const isAgencyIRS = agency === "irs";
      const irsEndPoint = "/transmission/getirstransmissionsbyemail";
      const hacEndpoint = "/transmission/gethactransmissionsbyemail";

      const response = await zendeskEfileApi.get(
        isAgencyIRS ? irsEndPoint : hacEndpoint,
        {
          params: {
            taxYear,
            returnType,
            email,
          },
        }
      );

      const transmissions = response.data;

      dispatch({
        type: transmissionActions.setTransmissions,
        payload: transmissions,
      });
    } catch (error) {
      setError("Error fetching transmissions");
    } finally {
      setIsLoading(false);
    }
  };

  const getTransmissions = () => {
    const isQueryTypeConfirmationNumber = queryType === "confirmationNumber";
    const isQueryNameAndSSN = queryType === "nameAndSSN";

    if (isQueryTypeConfirmationNumber) {
      getTransmissionsByConfirmationNumber();
    } else if (isQueryNameAndSSN) {
      getTransmissionsByTaxPayerNameAndSSN();
    } else {
      // queryType === 'email'
      getTransmissionsByEmail();
    }
  };

  const filterTransmissionsByStatus = () => {
    const filteredTransmissions = transmissions.filter((transmission) => {
      return (
        transmission.transmissionStatus === selectedTransmissionStatus.value
      );
    });
    setFilteredTransmissionsByStatus(filteredTransmissions);
  };

  useEffect(() => {
    getTransmissions();
  }, []);

  useEffect(() => {
    filterTransmissionsByStatus();
  }, [selectedTransmissionStatus]);

  useEffect(() => {
    return () => {
      dispatch({ type: transmissionActions.resetTransmissionsStatus });
    };
  }, []);

  //console.log(transmissions);

  return (
    <div style={{ overflowX: "auto" }}>
      <Grid>
        <StyledRow>
          <Col>
            <Button
              isStretched
              size="small"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <Button.StartIcon>
                <LeftArrowUpStroke />
              </Button.StartIcon>
              Back to search
            </Button>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <StatusDropDown />
          </Col>
        </StyledRow>
        <StyledRow>
          <ResultsList
            rowData={
              selectedTransmissionStatus.value === null
                ? transmissions
                : filteredTransmissionsByStatus
            }
            isLoading={isLoading}
            error={error}
          />
        </StyledRow>
      </Grid>
    </div>
  );
};

export default TransmissionsPage;

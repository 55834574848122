import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { zendeskEfileApi } from "../../utils/axiosInstance";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as CopyIcon } from "@zendeskgarden/svg-icons/src/16/copy-stroke.svg";
import { ReactComponent as ReloadIcon } from "@zendeskgarden/svg-icons/src/16/reload-stroke.svg";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import Loading from "../../components/Utilities/Loading";
import ErrorPage from "../../components/Utilities/ErrorPage";
import TransmissionLogsList from "../../components/TransmissionLogs/TransmissionLogsList";

import { Button, IconButton } from "@zendeskgarden/react-buttons";
import { Row, Grid, Col } from "@zendeskgarden/react-grid";
import { Accordion } from "@zendeskgarden/react-accordions";
import { ReactComponent as LeftArrowUpStroke } from "@zendeskgarden/svg-icons/src/16/arrow-left-up-stroke.svg";
import { LG, SM } from "@zendeskgarden/react-typography";
import { PALETTE } from "@zendeskgarden/react-theming";
import { Tag } from "@zendeskgarden/react-tags";
import { Spinner } from "@zendeskgarden/react-loaders";

import {
  useTransmissions,
  useTransmissionsDispatch,
  transmissionActions,
} from "../../context/TransmissionsContext";
import { useAlertsDispatch, alertsActions } from "../../context/AlertsContext";

import { formatDate } from "../../utils/formatDate";
import { copyToClipboard } from "../../utils/copyToClipboard";
import CancelConfirmationModal from "./CancelConfirmationModal";
import { useZafClient } from "../../hooks/useZafClient";

const StyledRow = styled(Row)`
  margin-top: 15px;
`;

const StyledAccordion = styled(Accordion)`
  & button:first-child {
    padding-left: 0;
  }
`;
const StyledIconButton = styled(IconButton)`
  margin-left: 10px;
`;

const rotateAnimation = keyframes`
    0 {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const StyledReloadIcon = styled(ReloadIcon)`
  animation: ${rotateAnimation} 1.3s linear infinite;
`;

const TransmissionsDetail = () => {
  const [isLoading, setLoading] = useState(true);
  const [tagColor, setTagColor] = useState("grey");
  const [error, setError] = useState("");
  const [
    isCancelConfirmationModalVisible,
    setIsCancelConfirmationModalVisible,
  ] = useState(false);
  const [zendeskAgent, setZendeskAgent] = useState(null);
  const [wasCancellationSuccessful, setWasCancellationSuccessful] =
    useState(false);
  const [isRefreshingStatus, setIsRefreshingStatus] = useState(false);

  const client = useZafClient();
  const history = useHistory();
  const location = useLocation();
  const params = location.state;
  const alertsDispatch = useAlertsDispatch();

  const {
    confirmationNumber,
    transmissionId,
    returnType,
    taxYear,
    taxPayerName,
    last4SSN,
    agency,
  } = params;

  const { transmissionDetail } = useTransmissions();
  const dispatch = useTransmissionsDispatch();

  const showCancelButton =
    zendeskAgent?.role === "admin" &&
    agency === "hac" &&
    transmissionDetail.statusText !== "Deleted";

  const getTransmissionDetailById = async () => {
    try {
      const isAgencyIRS = agency === "irs";

      const irsEndpoint = `/transmission/getirstransmission`;
      const hacEndpoint = `/transmission/gethactransmission`;

      const response = await zendeskEfileApi.get(
        isAgencyIRS ? irsEndpoint : hacEndpoint,
        {
          params: {
            taxYear,
            transmissionId,
          },
        }
      );
      const transmissionDetail = response.data;

      dispatch({
        type: transmissionActions.setTransmissionDetail,
        payload: transmissionDetail,
      });
    } catch (error) {
      setError("Error fetching transmission.");
    } finally {
      setLoading(false);
    }
  };

  const getCurrentZendeskAgent = async () => {
    try {
      const requestData = {
        url: "/api/v2/users/me",
        type: "GET",
        dataType: "json",
      };

      const { user } = await client.request(requestData);

      setZendeskAgent(user);
    } catch (error) {
      setError("Could not fetch Zendesk agent.");
    }
  };

  const refreshStatus = async () => {
    try {
      setIsRefreshingStatus(true);
      const isAgencyIRS = agency === "irs";

      const irsEndpoint = `/transmission/getirsrefreshstatus?taxYear=${taxYear}&transmissionId=${transmissionId}&tenantId=${transmissionDetail.tenantId}`;
      const hacEndpoint = `/transmission/gethacrefreshstatus?taxYear=${taxYear}&transmissionId=${transmissionId}&tenantId=${transmissionDetail.tenantId}`;

      console.log(isAgencyIRS ? irsEndpoint : hacEndpoint);

      const response = await zendeskEfileApi.get(
        isAgencyIRS ? irsEndpoint : hacEndpoint
      );
      const transmissionDetailData = response.data;
      //console.log("refresh");
      //console.log(transmissionDetailData);

      dispatch({
        type: transmissionActions.setTransmissionDetail,
        payload: transmissionDetailData,
      });
    } catch (error) {
      alertsDispatch({
        type: alertsActions.addErrorAlert,
        payload: {
          title: "Fetching Error",
          message: "Could not refresh return status.",
        },
      });
      console.log(error);
    } finally {
      setLoading(false);
      setIsRefreshingStatus(false);
    }
  };

  const setStatusTagColor = () => {
    if (transmissionDetail.statusText == "Submitted") {
      setTagColor("blue");
    } else if (
      transmissionDetail.statusText == "Deleted" ||
      transmissionDetail.statusText == "Rejected"
    ) {
      setTagColor("red");
    } else if (transmissionDetail.statusText == "Exception") {
      setTagColor("#3A3A3A");
    } else {
      setTagColor("grey");
    }
  };

  const showCancelReturnConfirmationModal = () => {
    setIsCancelConfirmationModalVisible(true);
  };

  useEffect(() => {
    getCurrentZendeskAgent();
  }, []);

  useEffect(() => {
    setStatusTagColor();
  }, [transmissionDetail.statusText]);

  useEffect(() => {
    getTransmissionDetailById();

    return () => {
      dispatch({ type: transmissionActions.resetTransmissionDetail });
      setWasCancellationSuccessful(false);
    };
  }, [wasCancellationSuccessful]);

  if (isLoading) {
    return <Loading />;
  }

  if (error.length > 0) {
    return <ErrorPage message={error} />;
  }

  return (
    <>
      <CancelConfirmationModal
        isModalVisible={isCancelConfirmationModalVisible}
        setIsModalVisible={setIsCancelConfirmationModalVisible}
        transmission={transmissionDetail}
        taxYear={taxYear}
        setCancellationSuccessful={setWasCancellationSuccessful}
      />

      <div style={{ overflowX: "auto" }}>
        <Grid>
          <StyledRow>
            <Col>
              <Button
                isStretched
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                <Button.StartIcon>
                  <LeftArrowUpStroke />
                </Button.StartIcon>
                Back to results
              </Button>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <LG tag="span" isBold>
                Details
              </LG>
            </Col>
            <Col textAlign="center">
              <Tooltip content="Click to copy return ID">
                <IconButton
                  size="small"
                  isBasic={false}
                  onClick={() => copyToClipboard(transmissionDetail.returnId)}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip content="Refresh Status">
                <StyledIconButton
                  size="small"
                  isBasic={false}
                  onClick={() => refreshStatus()}
                >
                  {isRefreshingStatus ? <StyledReloadIcon /> : <ReloadIcon />}
                </StyledIconButton>
              </Tooltip>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <SM tag="span" isBold>
                Taxpayer Name:
              </SM>
              <SM>
                <span>{transmissionDetail.taxpayerName}</span>
              </SM>
            </Col>
            <Col>
              <SM tag="span" isBold>
                Last 4 SSN:
              </SM>
              <SM>
                <span>{transmissionDetail.last4SSN}</span>
              </SM>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <SM tag="span" isBold>
                Email:
              </SM>
              <SM>
                <span>{transmissionDetail.email}</span>
              </SM>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <SM tag="span" isBold>
                Confirmation Number:
              </SM>
              <SM>
                <span>{transmissionDetail.confirmationNumber}</span>
              </SM>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <SM tag="span" isBold>
                Return:
              </SM>
              <SM>
                <span>{transmissionDetail.taxpayerName}</span>
              </SM>
            </Col>
            <Col>
              <SM tag="span" isBold>
                Status:
              </SM>
              <SM>
                <Tag hue={tagColor}>
                  <span>{transmissionDetail.statusText}</span>
                </Tag>
              </SM>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <SM tag="span" isBold>
                Date:
              </SM>
              <SM>
                <span>{formatDate(transmissionDetail.transmissionDate)}</span>
              </SM>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <StyledAccordion level={1} defaultExpandedSections={[]}>
                <Accordion.Section>
                  <Accordion.Header>
                    <Accordion.Label>
                      <LG tag="span" isBold>
                        Logs
                      </LG>
                    </Accordion.Label>
                  </Accordion.Header>
                  <Accordion.Panel>
                    <TransmissionLogsList
                      returnType={returnType}
                      agency={agency}
                      taxYear={taxYear}
                      returnId={transmissionDetail.returnId}
                      wasCancellationSuccessful={wasCancellationSuccessful}
                      isRefreshingStatus={isRefreshingStatus}
                      transmission={transmissionDetail}
                    />
                  </Accordion.Panel>
                </Accordion.Section>
              </StyledAccordion>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              {showCancelButton ? (
                <Button
                  onClick={showCancelReturnConfirmationModal}
                  isDanger
                  isStretched
                >
                  Cancel Return
                </Button>
              ) : null}
            </Col>
          </StyledRow>
        </Grid>
      </div>
    </>
  );
};

export default TransmissionsDetail;

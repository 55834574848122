import React from 'react';
import { Field, Label, Input, Message } from '@zendeskgarden/react-forms';

const TextField = ({ label, value, onChange, isRequired, validationType, validationMessage }) => {
    return (
        <Field>
            <Label>{label}</Label>
            <Input 
                value={value}
                onChange={onChange}
                required={isRequired}
                validation={validationType}
            />
            <Message validation={validationType}>{validationMessage}</Message>
        </Field>
    );
};

export default TextField;
import { useEffect, useState } from "react";

let zafClient = null;

export const useZafClient = () => {
  const [client, setClient] = useState(zafClient);

  useEffect(() => {
    if (!client && typeof window.ZAFClient !== "undefined") {
      zafClient = window.ZAFClient.init();

      setClient(zafClient);

      // @@@ NOTE(ivelez): The application size is specified in the manifest.json
      // size invoke resize would resize everything from the application including
      // the modal in the TransmissionLog component.
      // zafClient.invoke('resize', { width: '100%', height: '620px' });
    }
  }, [client]);

  return client;
};

import React, { useState, useEffect } from "react";
import { CodeBlock } from "@zendeskgarden/react-typography";
import { useParams } from "react-router-dom";
import { zendeskEfileApi } from "../../utils/axiosInstance";
import { useQueryStrings } from "../../hooks/useQueryStrings";
import ErrorPage from "../Utilities/ErrorPage";
import styled from "styled-components";
import Loading from "../Utilities/Loading";

const StyledCodeBlock = styled(CodeBlock)`
  height: 100%;
`;

const TransmissionJsonResponse = () => {
  const { id } = useParams();
  const [returnId, returnType, agency, taxYear] = useQueryStrings().values();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [transmissionLogs, setTransmissionLogs] = useState([]);

  const getLogs = async () => {
    try {
      const response = await zendeskEfileApi.get("/logs/gettransmissionlogs", {
        params: {
          agency,
          taxYear,
          returnType,
          returnId,
        },
      });
      const logs = await response.data.logs;
      setTransmissionLogs(logs);
    } catch (error) {
      setError("Error fetching transmission logs.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLogs();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (error.length > 0) {
    return <ErrorPage message={error} />;
  }

  if (transmissionLogs.length === 0) {
    return <ErrorPage message="No logs found." />;
  }

  const json = transmissionLogs.find(
    (log) => log.id === Number(id)
  ).responseJson;

  return (
    <StyledCodeBlock isNumbered language="json" size="large">
      {json
        ? JSON.stringify(JSON.parse(json), null, 4)
        : "No JSON response found"}
    </StyledCodeBlock>
  );
};

export default TransmissionJsonResponse;

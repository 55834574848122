import { useState } from "react";
import { LG } from "@zendeskgarden/react-typography";
import { IconButton } from "@zendeskgarden/react-buttons";
import styled from "styled-components";
import { ReactComponent as XIcon } from "@zendeskgarden/svg-icons/src/16/x-fill.svg";

const Container = styled.div`
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  border-radius: 5px;
  padding: 1rem;
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
`;

export const SeasonQuote = () => {
  const [isOpen, setIsOpen] = useState(Math.random() > 0.8);

  // Si... ¿Y? ¿Bailamos o lloramos? 💃 - 2023 season
  // Todo va a estar bien 💪 - Timeless

  const quotes = ["Si... ¿Y? ¿Bailamos o lloramos? 💃"];
  const rareQuote = ["Todo va a estar bien 💪"];

  const getRandomQuote = () => {
    const random = Math.random();
    if (random < 0.9) {
      return quotes[Math.floor(Math.random() * quotes.length)];
    } else {
      return rareQuote[Math.floor(Math.random() * rareQuote.length)];
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Container>
      <LG>{getRandomQuote()}</LG>
      <IconButton onClick={() => setIsOpen(false)} size="small">
        <XIcon style={{ color: "white" }} />
      </IconButton>
    </Container>
  );
};

import { useMsal } from "@azure/msal-react";
import { IconButton } from "@zendeskgarden/react-buttons";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { ReactComponent as SignOutIcon } from "@zendeskgarden/svg-icons/src/16/exit-stroke.svg";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SignOut = () => {
  const { instance } = useMsal();

  return (
    <Container>
      <Tooltip content="Sign out">
        <IconButton
          onClick={() => {
            instance.logoutPopup();
          }}
        >
          <SignOutIcon />
        </IconButton>
      </Tooltip>
    </Container>
  );
};

import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import SearchPage from "./pages/SearchPage/SearchPage";
import TransmissionsPage from "./pages/TransmissionsPage/TransmissionsPage";
import TransmissionsDetail from "./pages/TransmissionDetailPage/TransmissionsDetail";

import { useZafClient } from "./hooks/useZafClient";

import { TransmissionsProvider } from "./context/TransmissionsContext";
import { AlertsProvider } from "./context/AlertsContext";
import TransmissionJsonResponse from "./components/TransmissionLogs/TransmissionJsonResponse";
import AlertList from "./components/Alerts/AlertList";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "./msalConfig";
import { SignIn } from "./components/Auth/SignIn";
import { SignOut } from "./components/Auth/SignOut";
import { SeasonQuote } from "./components/SeasonQuote";
import { AxiosInterceptors } from "./components/Axios/AxiosInterceptors";

const App = () => {
  const client = useZafClient();
  const [isZendesk, setIsZendesk] = useState(false);

  useEffect(() => {
    if (client) {
      client.get("instances").then((data) => {
        if (Object.keys(data.instances).length > 0) {
          setIsZendesk(true);
        }
      });
    }
  }, [client]);

  if (!isZendesk) {
    return "This app only works in Zendesk. Please install it in Zendesk.";
  }

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <SignIn isZendesk={isZendesk} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AxiosInterceptors>
          <AlertsProvider>
            <TransmissionsProvider>
              <AlertList />
              <SignOut />
              <SeasonQuote />
              <Switch>
                <Route path="/" exact component={SearchPage} />
                <Route
                  path="/transmissions"
                  exact
                  component={TransmissionsPage}
                />
                <Route
                  path="/transmissions/:id"
                  exact
                  component={TransmissionsDetail}
                />
                <Route
                  path="/transmissions/:id/json"
                  exact
                  component={TransmissionJsonResponse}
                />
              </Switch>
            </TransmissionsProvider>
          </AlertsProvider>
        </AxiosInterceptors>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;

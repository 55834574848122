import React from "react"
import styled from 'styled-components';
import { LG } from '@zendeskgarden/react-typography';
import { Title, Paragraph } from '@zendeskgarden/react-notifications';
import { PALETTE } from '@zendeskgarden/react-theming'

const Container = styled.div`
    display: grid;
    place-items: center;
    padding: 15px;
`

const IconContainer = styled.div`
    color: ${PALETTE.kale[700]};
    opacity: 0.5;
`

const ErrorPage = ({ message = 'Sorry, your search was invalid. Please try again!', icon}) => {
    return (
        <Container>
            <Title>
                <IconContainer>
                    {icon}
                </IconContainer>
            </Title>
            <Paragraph style={{ color: PALETTE.grey[800]}}>
                    {message}
            </Paragraph>
        </Container>
    );
}


export default ErrorPage;
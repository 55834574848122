import React, { useEffect } from "react";
import { zendeskEfileApi } from "../../utils/axiosInstance";
import {
  Dropdown,
  Menu,
  Item,
  Select,
  Field,
  Label,
  Message,
} from "@zendeskgarden/react-dropdowns";
import {
  transmissionActions,
  useTransmissions,
  useTransmissionsDispatch,
} from "../../context/TransmissionsContext";

const TaxYearField = ({ validationType, validationMessage }) => {
  const { taxYears, selectedTaxYear, selectedAgency } = useTransmissions();
  const dispatch = useTransmissionsDispatch();

  const getTaxYears = async () => {
    const isAgencyIRS = selectedAgency.value === "irs";

    const irsEndpoint = "/taxyearreturns/getirstaxyears";
    const hacEndpiont = "/taxyearreturns/gethactaxyears";

    const response = await zendeskEfileApi.get(
      isAgencyIRS ? irsEndpoint : hacEndpiont
    );
    const taxYears = await response.data;

    dispatch({
      type: transmissionActions.setTaxYears,
      payload: taxYears,
    });
  };

  const onTaxYearSelect = (taxYear) => {
    dispatch({
      type: transmissionActions.setTaxYear,
      payload: taxYear,
    });

    dispatch({ type: transmissionActions.resetSelectedTaxYearReturn });
  };

  useEffect(() => {
    getTaxYears();
  }, [selectedAgency]);

  return (
    <Dropdown
      selectedItem={selectedTaxYear}
      onSelect={onTaxYearSelect}
      required
      downshiftProps={{ itemToString: (item) => item && item.year }}
    >
      <Field>
        <Label>Tax Year</Label>
        <Select validation={validationType}>{selectedTaxYear.year}</Select>
        <Message validation={validationType}>{validationMessage}</Message>
      </Field>
      <Menu>
        {taxYears.map((taxYear) => (
          <Item key={taxYear.year} value={taxYear}>
            {taxYear.year}
          </Item>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default TaxYearField;

import { createContext, useReducer, useContext, useId } from 'react';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  alerts: []
}

// Alert structure
// const alert = {
//   title: "Example title",
//   message: "Example message"
// }

const AlertsContext = createContext(initialState);
const AlertsDispatchContext = createContext(null);

export const AlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertsReducer, initialState);

  return (
    <AlertsContext.Provider value={state}>
      <AlertsDispatchContext.Provider value={dispatch}>
        {children}
      </AlertsDispatchContext.Provider>
    </AlertsContext.Provider>
  )
}

export const useAlerts = () => {
  return useContext(AlertsContext);
}

export const useAlertsDispatch = () => {
  return useContext(AlertsDispatchContext);
}

export const alertsActions = {
  addSuccessAlert: "ADD_SUCCESS_ALERT",
  addWarningAlert: "ADD_WARNING_ALERT",
  addErrorAlert: "ADD_ERROR_ALERT",
  addInfoAlert: "ADD_INFO_ALERT",
  removeAlert : "REMOVE_ALERT",
  cleanupAlerts: "CLEANUP_ALERTS"
}

const alertsReducer = (state, action) => {
  const {
    addSuccessAlert,
    addWarningAlert,
    addErrorAlert,
    addInfoAlert,
    removeAlert,
    cleanupAlerts
  } = alertsActions;


  switch(action.type) {
    case addSuccessAlert: {
      return {
        ...state,
        alerts: [...state.alerts, {
          id: uuidv4(),
          type: 'success',
          ...action.payload
        }]
      }
    }

    case addWarningAlert: {
      return {
        ...state,
        alerts: [...state.alerts, {
          id: uuidv4(),
          type: 'warning',
          ...action.payload
        }]
      }
    }

    case addErrorAlert: {
      return {
        ...state,
        alerts: [...state.alerts, {
          id: uuidv4(),
          type: 'error',
          ...action.payload
        }]
      }
    }

    case addInfoAlert: {
      return {
        ...state,
        alerts: [...state.alerts, {
          id: uuidv4(),
          type: 'info',
          ...action.payload
        }]
      }
    }

    case removeAlert: {
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.id !== action.payload)
      }
    }

    case cleanupAlerts: {
      return {
        ...state,
        alerts: []
      }
    }

    default: {
      return state;
    }
  }
}
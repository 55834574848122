import React from 'react';
import { Timeline } from '@zendeskgarden/react-accordions';
import { Span } from '@zendeskgarden/react-typography';
import styled from 'styled-components';
import { formatDate } from '../../utils/formatDate';
import { Button } from '@zendeskgarden/react-buttons';
import { useZafClient } from '../../hooks/useZafClient';
import { ReactComponent as NewWindowFillIcon } from '@zendeskgarden/svg-icons/src/16/new-window-fill.svg';

export const StyledSpan = styled(Span).attrs({ isBold: true })`
  display: block;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;


const TransmissionLog = ({ transmissionLog, returnId, agency, returnType, taxYear }) => {
  const client = useZafClient();

  // @@@ NOTE(ivelez): The modal needs to be created using the zafClient so that
  // the modal opens relative to the browser and not the application's iframe.
  // See docs: https://developer.zendesk.com/api-reference/apps/apps-support-api/modal/
  // More docs: https://developer.zendesk.com/documentation/apps/app-developer-guide/using-the-apps-framework/#using-modal-dialogs

  const formattedDate = formatDate(transmissionLog.logDate)

  const showModal = () => {
    client.invoke('instances.create', {
      location: 'modal',
      url: `${window.location.origin}/transmissions/${transmissionLog.id}/json?returnId=${returnId}&returnType=${returnType}&agency=${agency}&taxYear=${taxYear}`,
      size: {
        // optional 
        // @@@ NOTE(ivelez): The application size is specified in the manifest.json
        // if we specify it using client.invoke('resize', { width: '100%', height: '620px' })
        // it would overwrite the width and height specified in this object.
        width: '1000px',
        height: '400px'
      }}).then(function(modalContext) { 
        // The modal is on screen now 
        var modalClient = client.instance(modalContext['instances.create'][0].instanceGuid); 
        modalClient.on('modal.close', function() { 
        // The modal has been closed 
        });
      });
  }

  return(
    <Timeline.Item >
        <Timeline.Content>
          <StyledSpan>{transmissionLog.logTypeText}</StyledSpan>
          <Span hue="grey">{formattedDate}</Span>
          <StyledButton isBasic onClick={showModal}>
            <Button.StartIcon>
              <NewWindowFillIcon />
            </Button.StartIcon>
            See JSON Response</StyledButton>
        </Timeline.Content>
    </Timeline.Item >
  )
}

export default TransmissionLog;
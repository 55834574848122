import React from 'react';
import { Alert as ZendeskAlert, Title, Close } from '@zendeskgarden/react-notifications';
import { useAlertsDispatch, alertsActions } from '../../context/AlertsContext';
import styled from 'styled-components';

const StyledZendeskAlert = styled(ZendeskAlert)`
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
`;

const Alert = ({ alert }) => {
  const dispatch = useAlertsDispatch();

  const removeAlert = (alertId) =>  {
    dispatch({
      type: alertsActions.removeAlert,
      payload: alertId
    });
  }

  return (
    <>
        <StyledZendeskAlert type={alert.type}>
          <Title>{alert.title}</Title>
          {alert.message}
          <Close onClick={() => removeAlert(alert.id)} aria-label="Close Alert" />
        </StyledZendeskAlert>
    </>
  )
};

export default Alert;
